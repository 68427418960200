<template>
  <section class="content content--blue centered">
    <div class="content-authenticator">
      <client-only>
        <amplify-authenticator
          v-if="!forgotPassword"
          :auth-config="authConfig"
        ></amplify-authenticator>
        <amplify-sign-up v-if="signUp"></amplify-sign-up>
        <amplify-forgot-password
          v-if="forgotPassword"
          :forgotPasswordConfig="forgotPasswordConfig"
          usernameAttributes="Email"
        ></amplify-forgot-password>
      </client-only>
      <!-- <amplify-sign-out v-if="signedIn"></amplify-sign-out> -->
      <!-- <div v-if="!signedIn" class="social-container">
        <button
          class="social-container__button social-container__button-google"
          @click.prevent="googLogin"
        >
          <font-awesome-icon
            class="fa-lg"
            :icon="['fab', 'google']"
            style="color: red"
          />
          <span>Sign in with Google</span>
        </button>
      </div> -->
    </div>
    <div v-show="confirmAccountStep" class="login-help">
      A confirmation code will be sent to your email address. If you do not find
      the email, please first check your spam folder, then contact
      support@resiliencecalculator.com.
    </div>
  </section>
</template>

<script>
import consola from "consola";
import { Auth } from "aws-amplify";

function modeChange(event) {
  if (this.accountLink)
    this.accountLink.removeEventListener("click", modeChange.bind(this));
  if (this.signInButton)
    this.signInButton.removeEventListener("click", signInClick);
  if (this.signUpCreateAccountButton)
    this.signUpCreateAccountButton.removeEventListener("click", signInClick);
  if (this.confirmSignUpButton)
    this.confirmSignUpButton.removeEventListener("click", signInClick);
  if (this.forgotPasswordButton)
    this.forgotPasswordButton.removeEventListener("click", signInClick);
  if (this.forgotPasswordLink)
    this.forgotPasswordLink.removeEventListener("click", signInClick);
  setTimeout(() => {
    this.accountLink = document.querySelector(
      "a[data-test='sign-in-create-account-link']"
    );
    if (!this.accountLink)
      this.accountLink = document.querySelector(
        "a[data-test='sign-up-sign-in-link']"
      );
    if (!this.accountLink)
      this.accountLink = document.querySelector(
        "a[data-test='confirm-sign-up-back-to-sign-in-link']"
      );
    this.accountLink.addEventListener("click", modeChange.bind(this));
    this.signInButton = document.querySelector(
      "button[data-test='sign-in-sign-in-button']"
    );
    // consola.info(this.signInButton);
    this.signUpCreateAccountButton = document.querySelector(
      "button[data-test='sign-up-create-account-button']"
    );
    this.confirmSignUpButton = document.querySelector(
      "button[data-test='confirm-sign-up-confirm-button']"
    );
    this.forgotPasswordButton = document.querySelector(
      "button[data-test='forgot-password-send-code-button']"
    );
    this.forgotPasswordLink = document.querySelector(
      "a [data='sign-in-forgot-password-link']"
    );
    if (this.signInButton)
      this.signInButton.addEventListener("click", signInClick);
    if (this.signUpCreateAccountButton)
      this.signUpCreateAccountButton.addEventListener("click", signInClick);
    if (this.confirmSignUpButton)
      this.confirmSignUpButton.addEventListener("click", signInClick);
    if (this.forgotPasswordButton)
      this.forgotPasswordButton.addEventListener("click", signInClick);
    if (this.forgotPasswordLink)
      this.forgotPasswordLink.addEventListener("click", signInClick);
  }, 150);
}
function signInClick(event) {
  // const div = event.currentTarget.closest("div");
  // event.currentTarget.style.backgroundColor = "#9A5E00";
  // const overlay = document.querySelector(".overlay");
  // overlay.style.display = "block";
  // event.currentTarget.setAttribute("disabled", "disabled");
}

export default {
  // middleware: ["auth"],
  data() {
    return {
      accountLink: null,
      signInButton: null,
      signUpCreateAccountButton: null,
      forgotPasswordButton: null,
      forgotPasswordLink: null,
      forgotPasswordConfig: {
        header: "Forgot Password",
      },
    };
  },
  computed: {
    authConfig() {
      return {
        usernameAttributes: "Email",
        signUpConfig: {
          hideAllDefaults: true,
          signUpFields: [
            {
              label: "Email",
              key: "username",
              required: true,
              displayOrder: 1,
              type: "string",
              signUpWith: true,
            },
            {
              label: "Password",
              key: "password",
              required: true,
              displayOrder: 2,
              type: "password",
            },
          ],
        },
      };
    },
    forgotPassword() {
      return this.$store.state.user.forgotPassword || false;
    },
    signedIn() {
      return !!this.$store.state.user.authenticatedUser;
    },
    signUp() {
      return this.$store.state.createAccount;
    },
    authState() {
      return this.$store.state.authState;
    },
    confirmAccountStep() {
      return this.$store.state.confirmAccountStep;
    },
  },

  created() {
    this.$store.dispatch("user/clear");
    this.$store.dispatch("clear");
    this.$cookies.remove("cognito-username");
    this.$cookies.remove("brc-token");

    if (process.client) {
      Auth.signOut()
        .then(data => {
          consola.log(data);
          localStorage.clear();
        })
        .catch(err => {
          consola.debug(err);
        });
    }
  },

  mounted() {
    consola.info("login mounted");
    setTimeout(() => {
      if (this.$store.state.createAccount) {
        const link = document.querySelector(
          "a[data-test='sign-in-create-account-link']"
        );
        if (link) {
          link.click();
        }
        this.$store.commit("setCreateAccount", false);
      }
    }, 100);
    setTimeout(() => {
      this.accountLink = document.querySelector(
        "a[data-test='sign-in-create-account-link']"
      );
      if (this.accountLink) {
        this.accountLink.addEventListener("click", modeChange.bind(this));
      }
      this.signInButton = document.querySelector(
        "button[data-test='sign-in-sign-in-button']"
      );
      // consola.info(this.signInButton);
      this.signUpCreateAccountButton = document.querySelector(
        "button[data-test='sign-up-create-account-button']"
      );
      this.confirmSignUpButton = document.querySelector(
        "button[data-test='confirm-sign-up-confirm-button']"
      );
      this.forgotPasswordButton = document.querySelector(
        "button[data-test='forgot-password-send-code-button']"
      );
      this.forgotPasswordLink = document.querySelector(
        "a[data-test='sign-in-forgot-password-link']"
      );
      if (this.signInButton)
        this.signInButton.addEventListener("click", signInClick);
      if (this.signUpCreateAccountButton)
        this.signUpCreateAccountButton.addEventListener("click", signInClick);
      if (this.confirmSignUpButton)
        this.confirmSignUpButton.addEventListener("click", signInClick);
      if (this.forgotPasswordButton)
        this.forgotPasswordButton.addEventListener("click", signInClick);
      if (this.forgotPasswordLink)
        this.forgotPasswordLink.addEventListener("click", signInClick);
    }, 150);
  },

  destroyed() {
    if (this.accountLink)
      this.accountLink.removeEventListener("click", modeChange.bind(this));
    if (this.signInButton)
      this.signInButton.removeEventListener("click", signInClick);
    if (this.signUpCreateAccountButton)
      this.signUpCreateAccountButton.removeEventListener("click", signInClick);
    if (this.confirmSignUpButton)
      this.confirmSignUpButton.removeEventListener("click", signInClick);
    if (this.forgotPasswordButton)
      this.forgotPasswordButton.removeEventListener("click", signInClick);
    if (this.forgotPasswordLink)
      this.forgotPasswordLink.removeEventListener("click", signInClick);
  },

  methods: {
    fbLogin() {
      Auth.federatedSignIn({ provider: "Facebook" });
    },
    googLogin() {
      Auth.federatedSignIn({ provider: "Google" });
    },
    storeUser(user) {
      this.$store.commit("user/updateAuthenticatedUser", user);
    },
    async logAuth() {
      try {
        const u = await Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        consola.info("currentAuthenticatedUser:", u);
      } catch (error) {
        consola.info("authed user status:", error);
      }
    },
  },
};
</script>

<style lang="scss">
// Override AWS-Amplify button
button[data-test='sign-in-sign-in-button'],
button[data-test='sign-up-create-account-button'] /** add functionality for this **/,
button[data-test='confirm-sign-up-confirm-button'] /** add functionality for this **/,
button[data-test='forgot-password-send-code-button'] /** add functionality for this **/ {
  // width: 12rem;
  height: 3rem;
  // &:disabled {
  //   font-size: 0px !important;
  //   color: white !important;
  //   background: linear-gradient(90deg, #9a5e00, #fa9900, #fa9900, #9a5e00);
  //   background-size: 400% 400%;
  //   animation: gradient 2s ease 5;
  //   animation-fill-mode: forwards;

  //   &::after {
  //     font-size: 12px !important;
  //     color: white !important;
  //     text-transform: normal !important;
  //     content: "Loading...";
  //   }
  // }
}
@keyframes gradient {
  0% {
    background-position: 125% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.Anchor__a___3JUCG {
  margin-right: 1rem;
}
.centered {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}

.content-authenticator {
  position: relative;
  margin-top: 4rem;
  font-family: "Gotham A", "Gotham B", sans-serif;
  .error {
    color: var(--tomato);
    // font-size: 14px;
  }

  .social-container {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-content: flex-start;
    align-items: center;
    // background-color: white;
    margin: 0;
    padding: 0;
    &__button {
      all: unset;
      font-family: "Gotham A", "Gotham B", sans-serif;
      font-size: 14px;
      height: 50px;
      align-self: auto;
      border: none;
      cursor: pointer;
      span {
        margin-left: 0.5rem;
      }
      &-facebook {
        background-color: rgb(59, 89, 152);
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
        color: white;
        float: left;
        max-width: 250px;
        min-height: 40px;
        outline: none;
        padding: 0px 16px;
        &:hover {
          background-color: #3478f2;
        }
      }
      &-google {
        background-color: rgb(252, 252, 252);
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
        color: black;
        float: right;
        max-width: 250px;
        min-height: 40px;
        outline: none;
        padding: 0px 16px;
        &:hover {
          background-color: rgb(253, 234, 234);
        }
      }
    }
  }
}

.login-help {
  margin-top: 2rem;
  padding: 1rem;
  font-size: 12pt;
  font-family: "Gotham A", "Gotham B", sans-serif;
  color: white;
  width: 430px;
}
</style>
